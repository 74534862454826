import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { isEmpty, path } from 'ramda';
import styled from 'styled-components';
import mailIcon from '../../images/mail.svg';
import moneyIcon from '../../images/money.svg';
import recruitIcon from '../../images/picWebAuthorized.svg';
import { contextStore } from '../../App';
import { oneDay } from '../../helper/tool';
import LineLiffOutcomePage from './LineLiffOutcomePage';

const ContentStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 47px;
	margin-top: ${(props) => (props.noMT ? '0px' : '60px')};

	h1 {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.25;
		text-align: center;
		color: #000000;
	}

	img {
		width: 150px;
		height: 150px;
		margin: 20px 0;
	}

	img.recruit-img {
		width: 240px;
		height: 240px;
	}

	img.logo {
		width: 60px;
		height: 60px;
		border: 0.5px solid #cccccc;
		border-radius: 4px;
	}

	.experience-fill {
		font-size: 14px;
		line-height: 1.43;
		text-align: center;
		color: #4a4a4a;
		padding: 0 46px;
		margin-bottom: 64px;
	}

	.product-attention {
		font-size: 13px;
		line-height: 1.54;
		color: #9e9e9e;
		padding: 0 4px;
		white-space: pre-line;
	}

	.cross-line {
		width: 100vw;
		border-bottom: 0.5px solid #e0e0e0;
		margin-bottom: 16.5px;
	}
`;

const PageContent = ({ eventType, warning, dateAbleToFillExperience }) => {
	switch (eventType) {
		case 'linkout':
			return (
				<ContentStyle>
					<h1 data-event="linkout">領取資格審核完畢，請至任務頁面領取金幣。</h1>
					<img src={moneyIcon} alt="experience filled success" />
				</ContentStyle>
			);
		case 'survey':
			return (
				<ContentStyle>
					<h1 data-event="survey">問券填寫完成！</h1>
					<img src={mailIcon} alt="it's sending" />
					{/* <p className="experience-fill">金幣領取資格的審核時間為 { durationToSendProduction } 天，請於 {dateAbleToFillExperience.format("YYYY/M/D")} 後回此頁面確認資格。</p> */}
				</ContentStyle>
			);
		case 'feedback':
			return (
				<ContentStyle>
					<h1 data-event="feedback">心得已回填成功，請至任務頁面領取金幣。</h1>
					<img src={moneyIcon} alt="experience filled success" />
				</ContentStyle>
			);

		case 'recruit':
			return (
				<ContentStyle noMT>
					<img
						src={recruitIcon}
						className="recruit-img"
						alt="recruit success"
					/>
					<h1 data-event="recruit">恭喜成為會員！</h1>
				</ContentStyle>
			);
		case 'lineliff':
			return <LineLiffOutcomePage />;

		default:
			return (
				<ContentStyle>
					<h1 data-event="">
						名單已送出，預計於 {dateAbleToFillExperience.format('YYYY/M/D')}{' '}
						後寄達
					</h1>
					<img src={mailIcon} alt="it's sending" />
					<p className="experience-fill">
						請於 {dateAbleToFillExperience.format('YYYY/M/D')}{' '}
						後回來填寫心得，以取得金幣贈送資格
					</p>
					<div className="cross-line"></div>
					<p className="product-attention">{warning}</p>
				</ContentStyle>
			);
	}
};

function AlreadyApplyPage() {
	const { status, event } = useContext(contextStore);
	// const { data } = event;
	const eventType = path(['data', 'eventType'])(event);
	const warning = path(['data', 'warning'])(event);
	const durationToSendProduction = path(['data', 'durationToSendProduction'])(
		event
	);

	console.log('this is eventType:', eventType);

	// const { eventType, warning, durationToSendProduction } = path(['data'])(
	// 	event
	// );

	const daysToSendProduction =
		isNaN(durationToSendProduction) || isEmpty(durationToSendProduction)
			? 15
			: durationToSendProduction;
	const dateAbleToFillExperience = dayjs(
		Number(status) * 1000 + daysToSendProduction * oneDay
	).startOf('date');

	document.title = '送出成功';

	return (
		<div id="apply-success">
			{PageContent({ eventType, warning, dateAbleToFillExperience })}
		</div>
	);
}

export { PageContent, ContentStyle };
export default AlreadyApplyPage;
