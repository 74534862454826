import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import styled from 'styled-components'

const Loading = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.5;
`

function LoadingPage () {
	return (
		<Loading>
			<SyncLoader 
				size={12}
				margin={8}
				color={"#00aeef"}
			/> 
		</Loading>
	)
}

export default LoadingPage;