import React, { useContext } from 'react';
import ProductInfo from '../basic/ProductInfo';
import BannerLogo from '../basic/BannerLogo';
import Header from '../basic/Header';
import { contextStore } from '../../App';
import styled from 'styled-components';
import { path } from 'ramda';

const PageBody = styled.div`
	width: 100%;
	padding: ${(props) => (props.isRecruit ? '0' : '0 24px')};
	margin-bottom: 72px;
	position: relative;

	.loading-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1000;
		background-color: rgba(255, 255, 255, 0.1);
		top: 0;
		left: 0;
		/* border: #f00037 1px solid; */
	}
`;

function BasePage({ children }) {
	const { event, status, isProcessing } = useContext(contextStore);

	const bannerLink = path(['data', 'bannerLink'])(event);
	const productName = path(['data', 'productName'])(event);
	const logoLink = path(['data', 'logoLink'])(event);
	const productDescription = path(['data', 'productDescription'])(event);
	const eventType = path(['data', 'eventType'])(event);

	const isLinkout = eventType === 'linkout';
	const isRecruit = eventType === 'recruit' || eventType === 'lineliff';
	const isDashboard = eventType === 'dashboard';

	return (
		<>
			{status === '0' && !isLinkout && !isRecruit && !isDashboard && (
				<Header text="發票存摺活動專區" />
			)}
			<BannerLogo
				bannerLink={!isRecruit ? bannerLink : ''}
				logoLink={logoLink}
				eventType={eventType}
			/>
			<ProductInfo
				productName={productName}
				productDescription={productDescription}
				status={status}
				eventType={eventType}
			/>
			<PageBody isRecruit={isRecruit}>
				{isProcessing && <div className="loading-overlay"></div>}
				{children}
			</PageBody>
		</>
	);
}

export default BasePage;
