import React from 'react';
import { InputGroup, Textarea } from './style/InputStyle';
import { isNil, isEmpty } from 'ramda';

function UserTextArea(props) {
	const {
		label,
		inputType,
		inputMode,
		inputName,
		register,
		placeholder,
		errors,
		watch,
		eventType,
	} = props.userData;
	const hasError = errors[inputName];

	const isRecruitType = eventType === 'recruit' || eventType === 'lineliff';

	const checkError = (hasError) => {
		if (isNil(hasError)) {
			if (!isNil(watch(inputName)) && !isEmpty(watch(inputName))) {
				return <div className="check"></div>;
			}
		} else {
			switch (hasError.type) {
				case 'pattern':
					return <p className="error-hint">格式錯誤</p>;
				case 'required':
					return <p className="error-hint">必填欄位</p>;
				default:
					return null;
			}
		}
	};

	return (
		<InputGroup isRecruitType={isRecruitType}>
			<label>{label}</label>
			<div>
				<Textarea
					name={inputName}
					type={inputType}
					inputMode={inputMode}
					ref={register}
					placeholder={placeholder}
					errors={errors[inputName]}
				/>
				{checkError(hasError)}
			</div>
		</InputGroup>
	);
}

export default UserTextArea;
