import React, { useState } from 'react';
import { InputGroup, Input } from './style/InputStyle';
import { isEmpty, path } from 'ramda';
import styled from 'styled-components';
import addPicture from '../../images/icon-add-photo.svg';
import removePicture from '../../images/icon-cross-circle.svg';

const DropInputArea = styled.div`
	border: 1px dashed
		${(props) =>
			props.errors && props.eventType !== 'recruit' ? '#f00037' : '#ccc'};
	background-color: #fafafa;
	border-radius: 5px;
	color: #ccc;
	text-align: center;
	padding: 10px;
	margin: 4px 0;

	display: flex;
	align-items: center;
	justify-content: center;

	:focus {
		outline: none;
	}

	img {
		width: 24px;
		height: 24px;
		/* width: 100%; */
	}

	p {
		font-size: 12px;
		line-height: 1.33;
		color: ${(props) =>
			props.errors && props.eventType === 'recruit'
				? '#f00037'
				: props.eventType === 'recruit'
				? '#ccc'
				: '#000'};
	}
`;

const ImagePreviewAreaStyle = styled.div`
	width: 100%;
	position: relative;
	margin-top: 5px;

	.upload-img {
		border-radius: 4px;
		width: 100%;
	}
`;

function ImagePreviewArea({ file, handleRemoveFile }) {
	const preview = path(['preview'])(file);

	return (
		<ImagePreviewAreaStyle>
			<span className="remove-photo" onClick={() => handleRemoveFile(file)}>
				<img src={removePicture} alt="remove-photograph" />
			</span>
			<img className="upload-img" src={preview} alt="preview" />
		</ImagePreviewAreaStyle>
	);
}

function UserUploadFiles(props) {
	const {
		label,
		placeholder,
		inputName,
		register,
		errors,
		setValue,
		eventType,
	} = props.uploadData;
	const [files, setFiles] = useState([]);
	const isRecruit = eventType === 'recruit' || eventType === 'lineliff';

	const handleChange = (e) => {
		e.persist();
		// // 5MB = 5242880
		if (e.target.files[0].size > 5242880) {
			setValue(inputName, null);
			return alert('檔案太大了囉～\n目前只接受5MB以下的檔案喔！');
		}
		const reader = new FileReader();

		reader.onerror = () => alert('something wrong when uploading images...');
		reader.onload = () => {
			setFiles([
				Object.assign(e.target.files[0], {
					preview: URL.createObjectURL(e.target.files[0]),
				}),
			]);
		};
		reader.readAsDataURL(e.target.files[0]);
	};

	function handleRemoveFile(file) {
		const newFile = [...files];
		URL.revokeObjectURL(file.preview);
		newFile.splice(files.indexOf(file), 1);
		setValue(inputName, null);
		setFiles(newFile);
	}

	return (
		<InputGroup>
			<label style={!isRecruit ? { fontSize: '14px' } : {}}>{label}</label>
			<label htmlFor={inputName}>
				<DropInputArea
					eventType={eventType}
					errors={errors[inputName]}
					className={isEmpty(files) ? 'input-visible' : 'input-invisible'}
				>
					<img src={addPicture} alt="add" />
					<p>{placeholder}</p>
				</DropInputArea>
				<Input
					id={inputName}
					className={'input-invisible'}
					type="file"
					name={inputName}
					ref={register}
					accept="image/*"
					onChange={handleChange}
				/>
			</label>

			{!isEmpty(files) &&
				files.map((file, index) => {
					return (
						<ImagePreviewArea
							key={index}
							file={file}
							handleRemoveFile={handleRemoveFile}
						/>
					);
				})}
			{errors[inputName] &&
				errors[inputName].type === 'required' &&
				!isRecruit && <p className="error-hint">至少要上傳一張圖片</p>}
			{errors[inputName] &&
				errors[inputName].type === 'format' &&
				!isRecruit && <p className="error-hint">這張圖片上傳失敗！</p>}
		</InputGroup>
	);
}

export { ImagePreviewArea };
export default UserUploadFiles;
