import React from 'react';
import styled from 'styled-components';
import postboxIcon from '../../images/postbox.svg';

const EventOverStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.25;
		text-align: center;
		color: #000000;
		margin-top: 60px;
	}

	img {
		width: 150px;
		height: 150px;
		margin: 20px 0;
	}

	p {
		font-size: 14px;
		line-height: 1.43;
		text-align: center;
		color: #4a4a4a;
		margin-bottom: 200px;
	}
`;

function ApplyEventOverPage() {
	return (
		<EventOverStyle>
			<h1>活動額滿截止，敬請期待</h1>
			<img src={postboxIcon} alt="event is over" />
			<p>很抱歉，本活動名額已滿！</p>
		</EventOverStyle>
	);
}

export { EventOverStyle };
export default ApplyEventOverPage;
