import React from 'react';
import UserInput from '../component/input/UserInput';
import UserSelect from '../component/input/UserSelect';
import UserUploadFiles from '../component/input/UserUploadFiles';
import UserTextArea from '../component/input/UserTextArea';
import { is, isNil, map } from 'ramda';
import UserBirth from '../component/input/UserBirth';
import { getDistrcitOptions, inputConfigPairs } from './inputConfig';
import UserAddress from '../component/input/UserAddress';
import { strictValidationUserId } from './tool';

function checkInputConfig(type) {
	switch (typeof type) {
		case 'string': {
			if (isNil(inputConfigPairs[type])) return { type: 'error' };
			return inputConfigPairs[type];
		}
		default:
			return type;
	}
}

export default function customInputFunction({
	register,
	errors,
	setValue,
	control,
	watch,
}) {
	return function (input, eventType = '', additionalOptions = {}) {
		const inputConfigData = checkInputConfig(input);

		const {
			type,
			options,
			inputName,
			label,
			required,
			pattern,
			validate,
			IDStrictMode,
		} = inputConfigData;

		const regex = new RegExp(`^((?!defaultOption).)*$`);

		const isRecruitTypeOrLineLiff =
			eventType === 'recruit' || eventType === 'lineliff';

		// return early for 身份證
		if (inputName === 'ID') {
			const notStrictPattern = /^[A-Z]\d{9}$|^[A-Z][A-D]\d{8}$/;

			const idValidation = IDStrictMode
				? (value) =>
						strictValidationUserId(value) || '格式不符，請輸入有效的身分證'
				: (value) =>
						notStrictPattern.test(value) || '格式不符，請輸入有效的身分證';

			const inputData = {
				...inputConfigData,
				register: register({
					required: true,
					validate: { pattern: idValidation },
				}),
				errors,
				watch,
				eventType,
			};
			return <UserInput key={inputName} userData={inputData} />;
		}

		switch (type) {
			case 'input':
				const inputData = {
					...inputConfigData,
					register: register({ required: true, pattern, validate }),
					errors,
					watch,
					eventType,
				};

				return <UserInput key={inputName} userData={inputData} />;
			case 'select':
				const optionsArray = is(String, options) ? options.split(',') : [];
				const optionData = map((option) => {
					switch (option) {
						case optionsArray[0]:
							return (
								<option hidden disabled key={option} value="defaultOption">
									{option}
								</option>
							);
						default:
							return (
								<option key={option} value={option}>
									{option}
								</option>
							);
					}
				}, optionsArray);

				const selectData = {
					...inputConfigData,
					register: register({
						required: true,
						pattern: pattern || { value: regex, message: '必填欄位' },
					}),
					errors,
					watch,
					options: is(String, options) ? optionData : options,
					eventType,
				};
				return <UserSelect key={inputName} userData={selectData} />;

			case 'file':
				const uploadData = {
					...inputConfigData,
					register: register({ required: true, format: true }),
					errors,
					setValue,
					eventType,
				};
				return <UserUploadFiles key={inputName} uploadData={uploadData} />;

			case 'textarea':
				const textAreaData = {
					...inputConfigData,
					register: register({ required: true }),
					errors,
					watch,
					eventType,
				};
				return <UserTextArea key={inputName} userData={textAreaData} />;

			case 'birth':
				const { validate: ageValid } = additionalOptions;

				const birthData = {
					...inputConfigData,
					rules: { ...required, validate: ageValid },
					control,
					eventType,
					errors,
					watch,
				};
				return <UserBirth key={inputName} userData={birthData} />;
			case 'address':
				const {
					userCitySelect,
					userDistrictSelect,
					userAddressInput,
				} = inputConfigData;
				const userCityData = {
					...userCitySelect,
					register: register({
						required: true,
						pattern: pattern || { value: regex, message: '必填欄位' },
					}),
					errors,
					watch,
					eventType,
					hiddenLabel: isRecruitTypeOrLineLiff ? false : true,
				};

				const userDistrictData = {
					...userDistrictSelect,
					options: getDistrcitOptions(watch(userCitySelect.inputName)),
					register: register({
						required: true,
						pattern: pattern || { value: regex, message: '必填欄位' },
					}),
					errors,
					watch,
					eventType,
					hiddenLabel: isRecruitTypeOrLineLiff ? false : true,
				};

				const userAddressData = {
					...userAddressInput,
					errors,
					watch,
					register: register({ required: true }),
					eventType,
					hiddenLabel: isRecruitTypeOrLineLiff ? false : true,
				};

				return (
					<UserAddress
						key={userAddressInput.inputName}
						userData={{
							label,
							eventType,
							userCityData,
							userDistrictData,
							userAddressData,
						}}
					/>
				);

			default:
				return null;
		}
	};
}
