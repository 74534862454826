import React from 'react'
import styled from 'styled-components'

const ProductVideoStyle = styled.iframe`
	width: 100%;
	height: 183px;
	margin: 24px 0 0 ;
`

const ProductVideo = ({src, title}) => {
  return (
    <ProductVideoStyle src={`https://www.youtube.com/embed/${src}`} title={title} />
  )
}

export default ProductVideo