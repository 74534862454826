import React from 'react';
import styled from 'styled-components';
import { IOSswitch, Andriodswitch } from '../input/style/InputStyle';
import { isIOS } from 'react-device-detect';
import MarkdownTransformer from '../basic/MarkdownTransformer';

const SwitchStyle = styled.label`
	${(props) => (props.isIOS ? IOSswitch : Andriodswitch)};
`;

const Style = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 16px 10px 0;
	margin-left: 16px;
	background-color: #fff;

	span.recruit-policy {
		h3 {
			font-family: 'PingFang TC';
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 23px;
			margin-bottom: 2px;
		}

		.policy-description {
			font-family: 'PingFang TC';
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 18px;
			color: #9e9e9e;
		}
	}
	span.recruit-toggle {
		margin-left: 16px;
	}
`;

const Switch = ({ isIOS, switchAction }) => {
	return (
		<SwitchStyle isIOS={isIOS} className="switch">
			<input name="" type="checkbox" onChange={switchAction} />
			<span className="slider round"></span>
		</SwitchStyle>
	);
};

const UserSwitch = (props) => {
	const { onClick, userData } = props;
	const { text, description } = userData;

	return (
		<Style>
			<span className="recruit-policy">
				<h3>{text}</h3>
				<div className="policy-description">
					<MarkdownTransformer>{description}</MarkdownTransformer>
				</div>
			</span>
			<span className="recruit-toggle">
				<Switch isIOS={isIOS} switchAction={onClick} />
			</span>
		</Style>
	);
};

export default UserSwitch;
export { Switch };
