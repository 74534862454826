import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const Link = styled.a`
	color: #00aeef;
`;

export default function MarkdownTransformer({ children }) {
	return (
		<ReactMarkdown
			components={{
				a: ({ href, children }) => (
					<Link href={href} target="_blank" rel="noopener noreferrer">
						{children}
					</Link>
				),
			}}
		>
			{children}
		</ReactMarkdown>
	);
}
