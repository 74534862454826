import React from 'react';
import MAP from '../config.json';
import { isNil, map, path, nth, filter, compose, pick } from 'ramda';

const replaceSpace = (str) => str.replace(/[ |　]/gi, '');

const isAllLetterChinese = (str) =>
	replaceSpace(str).match(/[\p{Script=Han}]/giu)?.length ===
	replaceSpace(str).length;

export const getCityOptions = () => {
	const cityArray = map(pick(['CityName', 'value']))(MAP);
	const cityOption = map((i) => (
		<option key={path(['value'])(i)} value={path(['value'])(i)}>
			{path(['CityName'])(i)}
		</option>
	))(cityArray);
	const defaultOption = (
		<option hidden disabled key="0" value="defaultOption">
			縣市
		</option>
	);
	return [defaultOption, ...cityOption];
};

export const getDistrcitOptions = (value) => {
	const defaultOption = (
		<option hidden disabled key="0" value="defaultOption">
			鄉鎮區
		</option>
	);
	if (isNil(value) || value === 'defaultOption') {
		return [
			defaultOption,
			<option disabled key="1">
				請先選縣市
			</option>,
		];
	}
	const pickedCity = filter((addressList) => addressList.value === value)(MAP);
	const districtArray = compose(path(['AreaList']), nth(0))(pickedCity);
	const districtOption = map((i) => (
		<option key={path(['AreaName'])(i)} value={path(['AreaName'])(i)}>
			{path(['AreaName'])(i)}
		</option>
	))(districtArray);
	return [defaultOption, ...districtOption];
};

export const userNameInput = {
	type: 'input',
	label: '姓名',
	inputName: 'userName',
	inputType: 'text',
	inputMode: '',
	placeholder: '請填寫真實姓名',
	required: { value: true },
	validate: {
		pattern: (v) => replaceSpace(v).length >= 2 || '請輸入正確中文姓名',
		regex: (v) => isAllLetterChinese(v) || '請輸入正確中文姓名',
	},
};

export const userPhoneInput = {
	type: 'input',
	label: '手機號碼',
	inputName: 'userPhone',
	inputType: 'tel',
	inputMode: '',
	placeholder: '請填寫收件人手機號碼',
	pattern: { value: /09[0-9]{8}$/, message: '格式錯誤' },
};

export const userEmailInput = {
	type: 'input',
	label: '電子信箱',
	inputName: 'userEmail',
	inputType: 'text',
	inputMode: 'email',
	placeholder: '請填寫收件人電子信箱',
	pattern: {
		value: /^\w+((-\w+)|(\.\w+))*[@][A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
		message: '格式錯誤',
	},
};

export const userGenderSelect = {
	type: 'select',
	label: '性別',
	inputName: 'userGender',
	defaultValue: 'defaultOption',
	options: [
		<option key="預設值" hidden disabled value="defaultOption">
			請選擇
		</option>,
		<option key="男" value={1}>
			男
		</option>,
		<option key="女" value={2}>
			女
		</option>,
	],
};

export const userFillTextarea = {
	type: 'textarea',
	label: '心得',
	inputName: 'userFilled',
	placeholder: '請填寫心得',
	required: { value: true },
};

export const userBirthInput = {
	type: 'birth',
	label: '生日',
	inputName: 'userBirth',
	required: { required: true }, // this component use rules not register
};

export const addressInput = {
	type: 'address',
	label: '地址',
	userAddressInput: {
		type: 'input',
		inputName: 'userAddress',
		placeholder: '詳細地址',
		label: '街道巷號',
		required: { value: true },
	},
	userCitySelect: {
		type: 'select',
		inputName: 'userCity',
		label: '縣市',
		required: { value: true },
		defaultValue: 'defaultOption',
		options: getCityOptions(),
	},
	userDistrictSelect: {
		type: 'select',
		inputName: 'userDistrict',
		label: '鄉鎮區',
		required: { value: true },
		defaultValue: 'defaultOption',
	},
};

export const userPasswordData = {
	type: 'input',
	label: '密碼',
	inputName: 'userPassword',
	inputType: 'text',
	inputMode: '',
	placeholder: '必填',
	required: { value: true },
	pattern: { value: /^[A-Za-z0-9]*$/, message: '格式錯誤' },
	// eventType:'recruit',
};

export const inputConfigPairs = {
	'1': userNameInput,
	'2': userBirthInput,
	'3': userPhoneInput,
	'4': userEmailInput,
	'5': addressInput,
	'6': userGenderSelect,
	'7': userFillTextarea,
	'8': userPasswordData,
};
