import React, { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { isNil, isEmpty, toPairs, nth, compose, map } from 'ramda';
import styled from 'styled-components';
import AllApplyInfo from '../input/AllApplyInfo';
import ActionBtn from '../basic/ActionBtn';
import ProductVideo from '../basic/ProductVideo';
import EventDescriptionImg from '../basic/EventDescriptionImg';
import EventInfo from '../basic/EventInfo';
import AttentionInfo from '../basic/AttentionInfo';
import { mapIndexed } from '../../helper/handleEffect';
import { contextStore } from '../../App';
import { usePolicyCheck } from '../../helper/handleCustomHook';
import { ADDITIONAL_POLICY_INDEX } from '../../helper/tool';

const DeliveryInfoStyle = styled.div`
	h1 {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.11;
		color: #212121;
		margin-bottom: 12px;
	}
`;
const RecruitInputStyle = styled.div`
	form#recruit-info {
		border-bottom: 16px solid #e5e5e5;

		> div {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 16px;
			margin-bottom: 0;
			border-bottom: 0.5px solid #ccc;
			background-color: #fff;

			:last-child {
				border-bottom: none;
			}
			label:first-child {
				width: 20%;
				font-family: 'PingFang TC';
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 23px;
				color: #9e9e9e;
			}
			input,
			select,
			textarea {
				background-color: #fff;
				border: none;
				border-radius: 0;
				/* width: 80%; */
				margin: 1px 0;
				:focus {
					border: none;
					box-shadow: none;
				}

				::placeholder {
					font-weight: 400;
				}
			}

			select {
				width: 100%;
			}
			> div:not(.check) {
				width: 80%;
			}

			div.MuiFormControl-root {
				width: 100%;
			}

			input.MuiInputBase-input {
				padding: 12px;
				::placeholder {
					font-weight: 500;
				}
			}

			select {
				padding: 0 12px;
			}

			label:last-child {
				width: 80%;
				div.input-visible {
					background-color: #fff;
					border: none;
					flex-direction: row-reverse;
					justify-content: space-between;
					padding: 10px 15px 10px 12px;

					p {
						font-size: 16px;
						font-weight: 400;
					}
				}
			}
		}

		div.dropdown {
			right: 20px;
			bottom: 15px;
		}

		.error-hint {
			padding-left: 12px;
		}

		div.check {
			display: none;
		}
	}
`;

function UserApplyPage() {
	const { event, isProcessing, invosQueryString } = useContext(contextStore);
	const { endTime, data } = event;
	const eventEndTime = endTime ? dayjs(endTime).format('M/D') : '指定時間';
	const {
		videoLink,
		warning,
		policy,
		confirmStatement,
		eventType,
		eventDescription,
		eventDescriptionMediaInfos,
		linkoutBtnLink,
		agreeTerms,
	} = data;
	const isLinkout = eventType === 'linkout';
	const isRecruit = eventType === 'recruit';
	const isLineLiff = eventType === 'lineliff';
	const isDashboard = eventType === 'dashboard';
	const additionalAgreeTerms =
		isEmpty(agreeTerms) || isNil(agreeTerms) || typeof agreeTerms !== 'object'
			? []
			: compose(
					mapIndexed((policy, index) => [
						`policy${index + ADDITIONAL_POLICY_INDEX}`,
						...policy,
					]),
					toPairs
			  )(agreeTerms);

	const getPolicyList = (policyList = []) => {
		const additionalPolicy = map(nth(0))(additionalAgreeTerms);

		if (!isRecruit && !isLineLiff)
			return ['policy1', 'policy2'].concat(additionalPolicy);

		if (!isEmpty(policy)) policyList.push('policy1');
		if (!isEmpty(warning)) policyList.push('policy2');

		return policyList.concat(additionalPolicy);
	};
	const policyList = getPolicyList();
	const { disabledCondition, togglePolicyChecked } = usePolicyCheck(policyList);
	const hasPolicyCheckArea =
		!isEmpty(warning) ||
		!isEmpty(policy) ||
		(!isNil(confirmStatement) && !isEmpty(confirmStatement));
	const linkoutUrl = isEmpty(invosQueryString)
		? linkoutBtnLink
		: `${linkoutBtnLink}&${invosQueryString}`;

	if (isRecruit || isLineLiff) {
		return (
			<>
				<RecruitInputStyle>
					<AllApplyInfo />
					<AttentionInfo
						eventType={eventType}
						policy={policy}
						warning={warning}
						togglePolicyChecked={togglePolicyChecked}
						additionalPolicy={additionalAgreeTerms}
					/>
				</RecruitInputStyle>

				<ActionBtn
					text={'加入會員'}
					formId={'user-info'}
					disabledCondition={disabledCondition}
					isLoading={isProcessing}
					eventType={eventType}
				/>
			</>
		);
	}

	if (isDashboard) {
		return (
			<>
				{!isEmpty(videoLink) && <ProductVideo src={videoLink} />}
				{eventDescriptionMediaInfos &&
					mapIndexed((eventDescriptionMediaInfo, i) => (
						<EventDescriptionImg
							mediaInfo={eventDescriptionMediaInfo}
							key={i}
						/>
					))(eventDescriptionMediaInfos)}
				<EventInfo
					eventType={eventType}
					eventDescription={eventDescription}
					eventEndTime={eventEndTime}
				/>
			</>
		);
	}

	return (
		<>
			{!isEmpty(videoLink) && <ProductVideo src={videoLink} />}
			{eventDescriptionMediaInfos &&
				mapIndexed((eventDescriptionMediaInfo, i) => (
					<EventDescriptionImg mediaInfo={eventDescriptionMediaInfo} key={i} />
				))(eventDescriptionMediaInfos)}
			<EventInfo
				eventType={eventType}
				eventDescription={eventDescription}
				eventEndTime={eventEndTime}
			/>

			{!isNil(eventType) && !isLinkout && (
				// !isLinkout時 出現填資料表格
				<DeliveryInfoStyle>
					<AllApplyInfo />
				</DeliveryInfoStyle>
			)}

			{hasPolicyCheckArea && (
				// firebase裡 存在 warning or policy的時候就會有Attention Info
				<AttentionInfo
					eventType={eventType}
					warning={warning}
					policy={policy}
					confirmStatement={confirmStatement}
					togglePolicyChecked={togglePolicyChecked}
					additionalPolicy={additionalAgreeTerms}
				/>
			)}
			<ActionBtn
				formId={!isLinkout && 'user-info'}
				disabledCondition={hasPolicyCheckArea && disabledCondition}
				isLoading={isProcessing}
				eventType={eventType}
				linkoutUrl={linkoutUrl}
			/>
		</>
	);
}

export default UserApplyPage;
