import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import MAP from '../../config.json';
import dayjs from 'dayjs';
import {
	isNil,
	isEmpty,
	pick,
	map,
	includes,
	toPairs,
	filter,
	path,
	compose,
	unnest,
	split,
	zipObj,
	keys,
	and,
	is,
} from 'ramda';
import { contextStore } from '../../App';
import customInputFunction from '../../helper/useCustomInput';
import { useHandleResponse } from '../../helper/handleResponse';
import { handleImagesUpload } from '../../helper/handleApiCall';
import {
	convertImageValueToBase64,
	assembleInputValue,
	updateLocal,
	calculateAge,
} from '../../helper/tool';
import { inputConfigPairs } from '../../helper/inputConfig';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

const Style = styled.div`
	h1 {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.11;
		color: #212121;
		margin-bottom: 12px;
	}

	border-bottom: ${({ isRecruitType }) =>
		isRecruitType && '16px solid #e5e5e5'};
`;

function AllApplyInfo() {
	const {
		appCheck,
		userEventStatus,
		setUserDataParams,
		event,
		handleIsProcessing,
		handleAdConversionWithEndPoint: handleAdConversion,
	} = useContext(contextStore);

	const [selectArea, setSelectArea] = useState([]);

	const {
		inputDefaultWillNotShow,
		eventType,
		durationToSendProduction,
		liffId,
		liffAllowOldFriend,
		ageRange,
	} = path(['data'])(event);

	const durationToSendProductionReturnValue = and(
		!isNil(durationToSendProduction),
		is(Number, durationToSendProduction)
	)
		? durationToSendProduction
		: 15;

	const isRecruitType = eventType === 'recruit' || eventType === 'lineliff';
	const isLineLiff = eventType === 'lineliff';
	const { handleResponse } = useHandleResponse(() =>
		setUserDataParams((prevState) => ({
			...prevState,
			status: Number(Date.now()) / 1000,
		}))
	);
	const navigate = useNavigate();

	useEffect(() => {
		const { inputAreaList, inputAreaListGenerator } = event.data;
		if (isNil(inputAreaListGenerator) || isEmpty(inputAreaListGenerator)) {
			setSelectArea([]);
		} else {
			let inputList = inputAreaList.split(',');
			let selectList = pick(inputList, inputAreaListGenerator);
			setSelectArea(Object.values(selectList));
		}
	}, [event]);

	const {
		register,
		handleSubmit,
		errors,
		setError,
		control,
		setValue,
		watch,
	} = useForm({ mode: 'onBlur', shouldFocusError: false });

	const customInputGenerator = customInputFunction({
		register,
		handleSubmit,
		errors,
		control,
		setValue,
		watch,
	});

	const defaultInputOptionsGenerator = (key) => {
		switch (key) {
			case '2':
				const [minAge, maxAge] = !isNil(ageRange)
					? ageRange.split('~')
					: [-Infinity, Infinity];

				const checkAgeMatch = (age) => {
					const currentAge = calculateAge(age);
					return isEmpty(minAge)
						? currentAge <= maxAge
						: isEmpty(maxAge)
						? currentAge >= minAge
						: currentAge >= minAge && currentAge <= maxAge;
				};

				return {
					validate: {
						pattern: (value) => checkAgeMatch(value) || '資格不符',
					},
				};
			default:
				return {};
		}
	};

	const onSubmit = async (value) => {
		handleIsProcessing(true);

		const userAddressValue = () => {
			const userSelectedAddress = filter(
				(city) => city.value === value.userCity
			)(MAP);
			const userCity = path(['CityName'])(userSelectedAddress[0]);
			const userSelectedDistrictCode = compose(
				filter((city) => city.AreaName === value.userDistrict),
				path(['AreaList'])
			)(userSelectedAddress[0]);
			const userDistrictCode = path(['ZipCode'])(userSelectedDistrictCode[0]);
			return `${userDistrictCode}${userCity}${value.userDistrict}${value.userAddress}`;
		};

		let userInputData = {
			t: 'apply_trail',
			u: `${userEventStatus}`,
			rname: !includes('1', inputDefaultWillNotShow) ? value.userName : '',
			birthday: !includes('2', inputDefaultWillNotShow)
				? dayjs(value.userBirth).format('YYYYMMDD')
				: '',
			cmobile: !includes('3', inputDefaultWillNotShow) ? value.userPhone : '',
			email: !includes('4', inputDefaultWillNotShow) ? value.userEmail : '',
			addr: !includes('5', inputDefaultWillNotShow) ? userAddressValue() : '',
			gender: !includes('6', inputDefaultWillNotShow) ? value.userGender : 0,
			ucmt: !includes('7', inputDefaultWillNotShow) ? value.userFilled : '',
			pwd: !includes('8', inputDefaultWillNotShow) ? value.userPassword : '',
			dl: durationToSendProductionReturnValue,
		};

		const selectAreaKey = map((i) => path(['inputName'])(i))(selectArea);
		const selectAreaValue = map((i) => value[i])(selectAreaKey);
		const newValue = assembleInputValue(zipObj(selectAreaKey, selectAreaValue));
		const ImageInAValueArray = filter(
			(item) => !isNil(path(['0', 'type'])(item))
		)(value);

		if (isLineLiff) {
			const storageLiffId = compose(path([0]), split('-'))(liffId);

			updateLocal(storageLiffId, {
				...userInputData,
				liffId,
				liffUserSubmit: true,
				customInput: newValue,
				liffAllowOldFriend,
			});

			navigate(`/invosthankyou?liffClientId=${storageLiffId}&isFromInvos=true`);
			handleIsProcessing(false);
			return;
		}

		const dataObjectOfValue = { ...userInputData, 'cus_item[]': newValue };
		if (isEmpty(ImageInAValueArray)) {
			handleAdConversion(dataObjectOfValue).then((res) => {
				handleResponse(res);
				handleIsProcessing(false);
			});
		} else {
			const getImagesData = () => {
				return Promise.all(
					map((item) => convertImageValueToBase64(item, []))(
						map((item) => ({ file: item[1], name: item[0] }))(
							toPairs(ImageInAValueArray)
						)
					)
				);
			};
			const resultOfUpload = await handleImagesUpload(
				appCheck,
				unnest(await getImagesData())
			);

			if (resultOfUpload.code === 200) {
				handleAdConversion(dataObjectOfValue).then((res) => {
					handleResponse(res);
					handleIsProcessing(false);
				});
			} else if (resultOfUpload.code === 500 || resultOfUpload.code === 502) {
				const uploadFailList = path(['response', 'upload_fail_list'])(
					resultOfUpload
				);
				const failInput = map((item) => {
					return split('_', item.filename)[0];
				})(uploadFailList);
				const failFillInputList = unnest(
					map((fail) => {
						return filter((item) => item.inputName === fail)(selectArea);
					})(failInput)
				);

				handleIsProcessing(false);
				map((item) => setError(item.inputName, { type: 'format' }))(
					failFillInputList
				);
			}
		}
	};

	return (
		<Style isRecruitType={isRecruitType}>
			{!isRecruitType && <h1>請填寫下列資訊</h1>}

			<form id="user-info" onSubmit={handleSubmit(onSubmit)}>
				{map((key) => {
					const options = defaultInputOptionsGenerator(key);

					if (!includes(key, inputDefaultWillNotShow))
						return customInputGenerator(key, eventType, options);
				})(keys(inputConfigPairs))}

				{map((item) => customInputGenerator(item, eventType))(selectArea)}
			</form>
		</Style>
	);
}

export default AllApplyInfo;
