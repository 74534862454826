import React from 'react';
import styled from 'styled-components';
import MarkdownTransformer from './MarkdownTransformer';

const ProductInfoStyle = styled.div`
	display: flex;
	flex-flow: column;
	align-items: ${(props) => (props.isRecruit ? 'center' : 'flex-start')};
	padding: ${(props) =>
		props.isRecruit ? '12px 16px 36px 16px' : '12px 24px'};
	width: 100%;
	.product-company {
		font-size: ${(props) => (props.isRecruit ? '17px' : '24px')};
		font-weight: 600;
		line-height: ${(props) => (props.isRecruit ? '22px' : '34px')};
		color: #000;
		white-space: pre-line;
		margin: ${(props) => (props.isRecruit ? '8px 0px' : '0')};
	}

	.product-description {
		font-size: ${(props) => (props.isRecruit ? '15px' : '14px')};
		line-height: ${(props) => (props.isRecruit ? '22px' : '20px')};
		font-weight: normal;
		color: #212121;
		margin: ${(props) => (props.isRecruit ? '0 24px' : '12px 0 0 0')};
		white-space: pre-line;
	}
`;

const ProductInfo = ({
	productName,
	productDescription,
	status,
	eventType,
}) => {
	const isRecruit = eventType === 'recruit' || eventType === 'lineliff';
	const applySuccess = status !== '0';

	return (
		<ProductInfoStyle isRecruit={isRecruit}>
			<div className="product-company">{productName}</div>
			{applySuccess && isRecruit ? null : (
				<div className="product-description">
					<MarkdownTransformer>{productDescription}</MarkdownTransformer>
				</div>
			)}
		</ProductInfoStyle>
	);
};

export default ProductInfo;
