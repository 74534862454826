import React from 'react'
import styled from 'styled-components'
import ProductVideo from './ProductVideo'

const EventDescriptionImgLayout = styled.div`
  width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	margin-top: 24px;
	img {
		width: 100%;
	}
	span {
		margin-top: 8px;
		font-size: 12px;
		color: #363636;
		white-space: pre-line;
		text-align: center;
	}
`

function EventDescriptionImg (props) {
	const { mediaInfo } = props
	const { description, source, type } = mediaInfo
	
	return(
		<EventDescriptionImgLayout className="media-section">
			{( type === "image" ) && <img src={source} alt={description}/>}
			{( type === "video" ) && <ProductVideo src={source} title={description}/>}
			<span>{description}</span>
		</EventDescriptionImgLayout>
	)
}

export default EventDescriptionImg