import React from 'react';
import { SelectGroup, Select, SelectStyle } from './style/InputStyle';
import { isNil } from 'ramda';

function UserSelect(props) {
	const {
		label,
		inputName,
		register,
		errors,
		options,
		watch,
		eventType,
		hiddenLabel,
	} = props.userData;

	const hasError = errors[inputName];
	const inputValue = watch(inputName);
	const isRecruitType = eventType === 'recruit' || eventType === 'lineliff';

	const checkError = (hasError) => {
		if (!isNil(hasError)) {
			switch (hasError.type) {
				case 'pattern':
					if (isRecruitType) return;
					return <p className="error-hint">{hasError.message || '格式錯誤'}</p>;
				case 'required':
					if (isRecruitType) return;
					return <p className="error-hint">必填欄位</p>;
				default:
					return null;
			}
		}
	};

	return (
		<SelectGroup isRecruitType={isRecruitType}>
			{!hiddenLabel && <label>{label}</label>}
			<SelectStyle>
				<div className="dropdown"></div>

				<Select
					name={inputName}
					ref={register}
					style={{
						color:
							inputValue === undefined || inputValue === 'defaultOption'
								? hasError && isRecruitType
									? '#f00037'
									: '#ccc'
								: '#000',
					}}
					errors={hasError}
					defaultValue="defaultOption"
				>
					{options}
				</Select>
			</SelectStyle>
			{checkError(hasError)}
		</SelectGroup>
	);
}

export default UserSelect;
