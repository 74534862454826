import { useState } from 'react';
import { compose, includes, values, mergeAll } from 'ramda';

const usePolicyCheck = (policyList) => {
	const [policyChecked, setPolicyChecked] = useState(() =>
		mergeAll(policyList.map((policy) => ({ [policy]: false })))
	);
	const togglePolicyChecked = (policyName) => {
		setPolicyChecked((prevState) => ({
			...prevState,
			[policyName]: !prevState[policyName],
		}));
	};

	const disabledCondition = compose(includes(false), values)(policyChecked);

	return {
		disabledCondition,
		togglePolicyChecked,
	};
};

export { usePolicyCheck };
