import React from 'react';
import { InputGroup, Input } from './style/InputStyle';
import { isNil, isEmpty } from 'ramda';

function UserInput(props) {
	const {
		label,
		inputType,
		inputMode,
		inputName,
		placeholder,
		eventType,
		errors,
		register,
		watch,
		hiddenLabel,
	} = props.userData;

	const hasError = errors[inputName];
	const isRecruitType = eventType === 'recruit' || eventType === 'lineliff';

	const checkError = (hasError) => {
		if (isNil(hasError)) {
			if (!isNil(watch(inputName)) && !isEmpty(watch(inputName))) {
				return <div className="check"></div>;
			}
		} else {
			switch (hasError.type) {
				case 'pattern':
				case 'regex':
					return <p className="error-hint">{hasError.message || '格式錯誤'}</p>;
				case 'required':
					if (isRecruitType) return;
					return <p className="error-hint">必填欄位</p>;
				default:
					return null;
			}
		}
	};

	return (
		<InputGroup isRecruitType={isRecruitType}>
			{!hiddenLabel && <label>{label}</label>}
			<div>
				<Input
					name={inputName}
					type={inputType}
					inputMode={inputMode}
					ref={register}
					placeholder={placeholder}
					errors={errors[inputName]}
					eventType={eventType}
				/>
				{checkError(hasError)}
			</div>
		</InputGroup>
	);
}

export default UserInput;
