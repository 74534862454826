import React from 'react';
import styled from 'styled-components';
import { isNil, isEmpty } from 'ramda';
import MarkdownTransformer from './MarkdownTransformer';

const EventInfoStyle = styled.div`
	width: 100%;
	margin-top: 36px;

	h1 {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.11;
		color: #212121;
	}

	.event-description {
		font-size: 14px;
		color: #4a4a4a;
		white-space: pre-line;
		margin: 12px 0 38px;

		span {
			font-weight: 500;
			color: #f00037;
		}
	}
`;

const EventInfo = ({ eventDescription, eventType, eventEndTime }) => {
	return (
		<EventInfoStyle>
			<h1>活動說明</h1>
			{!isNil(eventDescription) && !isEmpty(eventDescription) ? (
				<div className="event-description">
					<MarkdownTransformer>{eventDescription}</MarkdownTransformer>
				</div>
			) : !isNil(eventType) && eventType === 'survey' ? (
				<div className="event-description">
					發票存摺任務獎勵金幣，完成問卷填寫，方符合贈點資格。問卷填寫完畢後回到發票存摺任務頁面即可領取金幣。
				</div>
			) : (
				<div className="event-description">
					發票存摺任務獎勵金幣，須於活動完成後，回到本網頁進行心得回填，方符合贈點資格。心得回填請於{' '}
					<span> {eventEndTime}</span> 前完成，逾期即喪失金幣贈予資格。
				</div>
			)}
		</EventInfoStyle>
	);
};

export default EventInfo;
