import { apiUrl } from '../config';
import { isNil, path, addIndex, map } from 'ramda';

const checkError = (response) => {
  if (path(['status'])(response) >= 200 && path(['status'])(response) <= 299) {
    return response.json();
  } else {
    throw isNil(path(['statusText'])(response))
     ? Error(response)
     : Error(path(['statusText'])(response))
  }
}
const fetcher = ({endPoint, config}) => {
  return fetch( `${apiUrl}/${endPoint}`, {
    ...config
  })
    .then(checkError)
}

const mapIndexed = addIndex( map );

export {
  fetcher,
  mapIndexed
}