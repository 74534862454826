import { compose, includes, isEmpty, isNil, omit, path, pick } from 'ramda';
import React from 'react';
import queryString from 'query-string';
import { getLocal } from '../../helper/tool';
import { ContentStyle } from './AlreadyApplyPage';
import recruitSuccess from '../../images/picWebAuthorized.svg';
import recruitFail from '../../images/recruitFail.svg';
import lineLoginFail from '../../images/lineLoginFail.svg';

export default function LineLiffOutcomePage({ liffLoginStatus }) {
	const queryStringParams = queryString.parse(window.location.search);
	const liffClientId = path(['liffClientId'])(queryStringParams);

	const hasLineLoginError =
		!isNil(path(['error'])(queryStringParams)) &&
		!isNil(path(['state'])(queryStringParams));

	const liffdataInLocalStorageExist =
		!isNil(getLocal(liffClientId)) && !isEmpty(getLocal(liffClientId));

	const logoLink =
		liffClientId && compose(path(['logoLink']), getLocal)(liffClientId);

	if (hasLineLoginError) {
		return (
			<ContentStyle noMT className="error-mission-page">
				<img src={lineLoginFail} alt="linelogin fail" />
				<h1>Line 登入授權失敗，請再試一次。</h1>
			</ContentStyle>
		);
	}

	if (!liffdataInLocalStorageExist) {
		//沒有localStorage可以打資料
		return (
			<ContentStyle noMT className="error-mission-page">
				<img src={lineLoginFail} alt="event is over" />
				<h1>任務參數異常，無法完成此任務。</h1>
			</ContentStyle>
		);
	}

	if (liffLoginStatus === 'unqualified') {
		return (
			<ContentStyle noMT className="error-mission-page">
				{liffClientId && <img className="logo" src={logoLink} alt="company" />}
				<img src={recruitFail} alt="event is over" />
				<h1>會員狀態不符合此活動資格。</h1>
			</ContentStyle>
		);
	}

	return (
		<ContentStyle noMT className="recruit-sucess-page">
			{liffClientId && <img className="logo" src={logoLink} alt="company" />}
			<img src={recruitSuccess} alt="event is over" />
			<h1>恭喜您加入Line好友</h1>
		</ContentStyle>
	);
}
