import React from 'react';
import postboxIcon from '../../images/postbox.svg';

function NoMission () {
  return (
    <div data-testid="no-mission" className="no-mission-page">
      <h1 data-testid="no-mission-title" className="no-mission-title">任務未開放</h1>
      <img width="150px" height="150px" src={postboxIcon} alt="There is no mission found!"/>
    </div>
  );
}

export default NoMission;