export const useHandleResponse = (action, eventType = '') => {
	const type = eventType === 'recruit' ? '會員' : '試用';
	const handleResponse = (res) => {
		switch (res) {
			case 200:
				action();
				return;
			case 305:
				alert('心得字元長度不足');
				return;
			case 351:
				alert('任務獎勵額滿');
				return;
			case 352:
				alert('無有效任務');
				return;
			case 353:
				alert('已領取過任務獎勵');
				return;
			case 410:
				alert(`會員註冊失敗，請確認會員資料是否已註冊過。`);
				return;
			case 422:
				alert(`此手機號碼已參加過活動，謝謝您的支持。`);
				return;
			case 452:
				alert(`重複申請${type}`);
				return;
			case 453:
				alert('未達可填寫心得日期');
				return;
			case 500:
				alert('系統繁忙 操作失敗');
				return;
			default:
				return;
		}
	};

	return { handleResponse };
};
