import React from 'react';
import UserSelect from './UserSelect';
import UserInput from './UserInput';
import styled from 'styled-components';

const AddressStyle = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	position: relative;
	width: 100%;
	label {
		font-size: 14px;
		color: #212121;
	}

	.error-hint {
		font-size: 12px;
		color: #f00037;
		margin-bottom: 4px;
	}

	> :nth-child(2) {
		border-bottom: ${({ isRecruitType }) =>
			isRecruitType && '0.5px solid #ccc;'};
	}
`;

const AddressSelect = styled.div`
	display: flex;
	flex-direction: ${({ isRecruitType }) => (isRecruitType ? 'column' : 'row')};
	gap: ${({ isRecruitType }) => (isRecruitType ? '0px' : '8px')};
	> div {
		label {
			width: ${({ isRecruitType }) => isRecruitType && '20%'};
		}
		width: ${({ isRecruitType }) => !isRecruitType && '50%'};
		input,
		select,
		textarea {
			width: 100%;
		}
		:nth-child(2) {
			border-bottom: ${({ isRecruitType }) =>
				isRecruitType && '0.5px solid #ccc;'};
		}
	}
`;

const UserAddress = ({ userData }) => {
	const {
		eventType,
		label,
		userCityData,
		userDistrictData,
		userAddressData,
	} = userData;

	const isRecruitType = eventType === 'recruit' || eventType === 'lineliff';

	return (
		<AddressStyle isRecruitType={isRecruitType}>
			{!isRecruitType && <label>{label}</label>}
			<AddressSelect isRecruitType={isRecruitType}>
				<UserSelect userData={userCityData} />
				<UserSelect userData={userDistrictData} />
			</AddressSelect>
			<UserInput userData={userAddressData} />
		</AddressStyle>
	);
};

export default UserAddress;
