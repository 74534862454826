import React, { useState, useEffect, createContext } from 'react';
import './style/reset.css';
import './style/style.css';
import queryString from 'query-string';
import LoadingPage from './component/content/LoadingPage';
import { isNil, split, isEmpty, path, zipObj, compose, keys } from 'ramda';
import Main from './component/Main';
import NoMission from './component/content/NoMission';
import { getDataFromFirebase } from './helper/getDataFromFirebase';
import {
	generatorFirebaseValue,
	setLocal,
	getLocal,
	getInvosQueryString,
} from './helper/tool';
import { handleAdConversion } from './helper/handleApiCall';
import { Route, Routes } from 'react-router';
import LineLiffEndPoint from './component/content/LineLiffEndPoint';

const contextStore = createContext({});

function App() {
	const [event, setEvent] = useState(null);
	const [isProcessing, handleIsProcessing] = useState(false);
	const [
		{
			status,
			eventId,
			appCheck,
			userEventStatus,
			invosQueryString,
			liffClientId,
		},
		setUserDataParams,
	] = useState(() => {
		const parsed = queryString.parse(window.location.search);
		const invosQueryString = !isEmpty(parsed) && getInvosQueryString(parsed);
		const userEventStatus = path(['event_status'])(parsed);
		const liffClientId = path(['liffClientId'])(parsed);
		const defaultDataParams = {
			status: '',
			eventId: '',
			appCheck: '',
		};
		const dataParamKeys = keys(defaultDataParams);
		const updateDataParams =
			!isNil(userEventStatus) &&
			compose(zipObj(dataParamKeys), split('*'))(userEventStatus);

		return {
			...defaultDataParams,
			...updateDataParams,
			userEventStatus,
			invosQueryString,
			liffClientId,
		};
	});

	const handleCatchError = () => {
		if (!isNil(userEventStatus) || !isNil(liffClientId)) {
			return;
		}
		setEvent({});
		document.title = '任務未開放';
	};

	useEffect(() => {
		if (isNil(userEventStatus) && isNil(liffClientId)) {
			handleCatchError();
			return;
		}

		getDataFromFirebase(`/trialWebData/eventIdList/${eventId}`)
			.then((e) => {
				const eventIdData = e.value;
				const localEventContent = getLocal(
					`event-${eventIdData.eventContentId}`
				);
				const checkForFirstTime = isNil(localEventContent);
				const checkForUpdate =
					!checkForFirstTime &&
					localEventContent.updateTime !== eventIdData.updateTime;

				if (checkForFirstTime || checkForUpdate) {
					getDataFromFirebase(
						`/trialWebData/eventContentList/${eventIdData.eventContentId}`
					)
						.then(({ value, key }) => {
							const eventContentData = generatorFirebaseValue(value);
							const eventData = { data: eventContentData, ...eventIdData };
							setLocal(`event-${key}`, eventData);
							setEvent(eventData);
						})
						.catch(() => {
							handleCatchError();
							return;
						});
				} else {
					setEvent(localEventContent);
				}
			})
			.catch(() => {
				handleCatchError();
				return;
			});
	}, [eventId, userEventStatus]);

	const handleAdConversionWithEndPoint = handleAdConversion(
		path(['endPoint'])(event)
	);

	return (
		<contextStore.Provider
			value={{
				event,
				setUserDataParams,
				status,
				eventId,
				appCheck,
				userEventStatus,
				invosQueryString,
				isProcessing,
				handleIsProcessing,
				handleAdConversionWithEndPoint,
			}}
		>
			<Routes path="/">
				<Route
					index
					element={
						isNil(event) ? (
							<LoadingPage />
						) : isEmpty(event) ? (
							<NoMission />
						) : (
							<Main />
						)
					}
				/>

				<Route path="/invosthankyou" element={<LineLiffEndPoint />} />
			</Routes>
		</contextStore.Provider>
	);
}

export { contextStore };
export default App;
