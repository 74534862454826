import styled, { css } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import { DatePicker } from '@material-ui/pickers';

export const recruitTypeStyle = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 16px;
	margin-bottom: 0;
	border-bottom: 0.5px solid #ccc;
	background-color: #fff;
	label {
		width: 20%;
	}
	> div {
		width: 100%;
	}

	:last-child {
		border-bottom: none;
	}

	input,
	select,
	textarea {
		background-color: #fff;
		border: none;
		border-radius: 0;
		width: 100%;
		margin: 1px 0;
		:focus {
			border: none;
			box-shadow: none;
			background-color: #fff;
		}

		::placeholder {
			font-weight: 400;
		}
	}

	.error-hint {
		padding-left: 12px;
	}
`;

const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${({ isRecruitType }) => (isRecruitType ? '0px' : '16px')};
	position: relative;

	label {
		font-size: 14px;
		color: #212121;
	}

	.input-form {
		position: relative;
	}

	.error-hint {
		font-size: 12px;
		color: #f00037;
		margin-bottom: 4px;
	}

	.input-visible {
		display: flex;
	}
	.input-invisible {
		display: none;
	}

	${({ isRecruitType }) => isRecruitType && recruitTypeStyle};
`;

const Input = styled.input`
	height: ${(props) => (props.lineheight ? '66px' : '44px')};
	width: 100%;
	border-radius: 4px;
	border: solid 1px ${(props) => (props.errors ? 'red' : '#ccc')};
	background-color: #fafafa;
	padding: 11px 36px 11px 12px;
	font-size: 16px;
	margin: 4px 0;

	::placeholder {
		color: ${(props) =>
			props.errors &&
			(props.eventType === 'recruit' || props.eventType === 'lineliff')
				? '#f00037'
				: '#ccc'};
	}
	:focus {
		outline: none;
		border: solid 1px #00aeef;
		background-color: #fafafa;
		caret-color: #00aeef;

		~ .check {
			display: none;
		}
	}
`;

const AddressTextarea = styled.textarea`
	width: 100%;
	height: ${(props) => (props.lineheight ? '66px' : '44px')};
	resize: none;
	border-radius: 4px;
	border: solid 1px ${(props) => (props.errors ? 'red' : '#ccc')};
	background-color: #fafafa;
	padding: 11px 42px 11px 12px;
	font-size: 16px;
	overflow-y: hidden;
	margin-bottom: 4px;

	:focus {
		outline: none;
		border: solid 1px #00aeef;
		background-color: #fafafa;
		caret-color: #00aeef;
	}

	::placeholder {
		color: #ccc;
	}

	~ .check {
		position: absolute;
		bottom: 50%;
		right: 12px;
		transform: rotate(-45deg);
		height: 9px;
		width: 17px;
		border-bottom: 2px solid #00aeef;
		border-left: 2px solid #00aeef;
		margin: 0;
	}
`;

const SelectGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${({ isRecruitType }) => (isRecruitType ? '10px' : '16px')};
	position: relative;

	.dropdown {
		position: absolute;
		bottom: 20px;
		right: 13px;
		width: 10px;
		height: 10px;
		pointer-events: none;
		border-top: 10px solid #ccc;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		z-index: 5;
	}

	label {
		font-size: 14px;
		color: #212121;
	}
	.error-hint {
		font-size: 12px;
		color: #f00037;
		margin-bottom: 4px;
	}

	.address-select-group {
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		.address-select {
			:first-child {
				margin-right: 4px;
			}
			:last-child {
				margin-left: 4px;
			}
		}
	}

	.address-select {
		position: relative;
		flex: 1;
		.dropdown {
			top: 23px;
		}

		select {
			width: 100%;
		}
	}

	${({ isRecruitType }) => isRecruitType && recruitTypeStyle};
`;

const Select = styled.select`
	width: 100%;
	height: 44px;
	border-radius: 4px;
	border: solid 1px ${(props) => (props.errors ? 'red' : '#ccc')};
	background-color: #fafafa;
	padding: 11px 12px;
	font-size: 16px;
	margin: 4px 0;

	::placeholder {
		color: #ccc;
	}
	:focus {
		outline: none;
		background-color: #fafafa;
		box-shadow: 0 0 0.8px 0.8px #00aeef;
		border: solid 1px #fff;
		caret-color: #00aeef;
	}

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
`;

const SelectStyle = styled.div`
	position: relative;
`;

const DatePickers = styled(DatePicker)`
	width: 100%;
	input {
		height: 20px;
		padding: 11px 12px;
		border-radius: 4px;
		background-color: #fafafa;
		border: 1px solid ${(props) => (props.errors ? 'red' : '#ccc')};
		margin: 4px 0;
		::placeholder {
			color: ${(props) =>
				props.errors &&
				(props.isrecruittype === 'recruit' ||
					props.isrecruittype === 'lineliff')
					? '#f00037ff'
					: '#999'};
			opacity: ${(props) =>
				props.errors &&
				(props.isrecruittype === 'recruit' ||
					props.isrecruittype === 'lineliff')
					? '0.8'
					: ' 0.45'};
		}
	}

	${({ isrecruittype }) =>
		(isrecruittype === 'recruit' || isrecruittype === 'lineliff') &&
		recruitTypeStyle}

	fieldset {
		display: none;
	}
`;

const Textarea = styled.textarea`
	resize: none;
	height: 200px;
	background: #fafafa;
	border: solid 1px ${(props) => (props.errors ? 'red' : '#ccc')};
	font-size: 16px;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 11px 12px;
	margin: 4px 0;
	:focus {
		outline: 1px auto #00aeef;
	}
	::placeholder {
		color: #ccc;
	}
`;

const IOSswitch = css`
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;

	input[type='checkbox'] {
		display: none;
		:focus {
			outline: none;
		}
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.2s;
		transition: 0.2s;
	}

	.slider:before {
		position: absolute;
		content: '';
		box-shadow: 0px 2px 2px #ccc;
		height: 30px;
		width: 30px;
		left: 2px;
		top: 2px;
		bottom: 2px;
		background-color: white;
		-webkit-transition: 0.2s;
		transition: 0.2s;
	}

	input:checked + .slider {
		background-color: #00aeef;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #00aeef;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
`;

const Andriodswitch = css`
	position: relative;
	display: inline-block;
	width: 45px;
	height: 20px;

	input[type='checkbox'] {
		display: none;
		:focus {
			outline: none;
		}
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.2s;
		transition: 0.2s;
	}

	.slider:before {
		position: absolute;
		content: '';
		box-shadow: 0px 2px 2px #ccc;
		height: 30px;
		width: 30px;
		left: -5px;
		top: -25%;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: #00aeef78;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #00aeef78;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(25px);
		-ms-transform: translateX(25px);
		transform: translateX(25px);
		background-color: #00aeef;
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
`;

export {
	InputGroup,
	Input,
	SelectGroup,
	Select,
	SelectStyle,
	AddressTextarea,
	DatePickers,
	Textarea,
	IOSswitch,
	Andriodswitch,
};
