import { fetcher } from './handleEffect';
import { 
  compose,
  forEach,
  toPairs
} from 'ramda';

const handleAdConversion = (endPoint) => ({ ...userInputData }) => {
  const userInputFormData = new FormData();
  compose(
    forEach(([ userInputFromItemKey, userInputFromItemValue]) => {
      userInputFormData.append(userInputFromItemKey, userInputFromItemValue)
    }), 
    toPairs
  )(userInputData);

  return fetcher({
    endPoint: endPoint || 'coops/ad/conversion', 
    config: {
      body: userInputFormData,
			method: 'POST',
    }
  })
}

const handleImagesUpload = (token,data) => {
  const imageData = {images:data}
  return fetcher({
    endPoint: 'imagepool/upload/', 
    config: {
      headers: new Headers({
        'Content-Type':'application/json',
        'token':token,
      }),
      body: JSON.stringify(imageData),
      method: 'POST'
    }
  })
}

export {
  handleAdConversion,handleImagesUpload
}