import React from 'react';
import styled from 'styled-components';
import { isEmpty, isNil } from 'ramda';
import UserCheckbox from '../input/UserCheckbox';
import UserSwitch from '../input/UserSwitch';
import MarkdownTransformer from './MarkdownTransformer';

const AttentionInfoStyle = styled.div`
	padding-bottom: 18px;
	margin-bottom: 68px;
	h1 {
		font-size: 20px;
		font-weight: 600;
		line-height: 1;
		color: #212121;
		margin-top: 32px;
	}
	.attention-context {
		font-size: 14px;
		color: #4a4a4a;
		margin: 12px 0 28px;
		white-space: pre-line;
	}
	.attention-policy {
		font-size: 12px;
		line-height: 1.67;
		color: #9b9b9b;
		margin-bottom: 24px;
		white-space: pre-line;
		a {
			font-size: 12px;
			color: #00aeef;
		}
		p {
			display: inline;
		}
	}
`;
const RecruitPolicyStyle = styled.div`
	background-color: #fff;
	padding: 0;
	margin-bottom: 64px;
	p.warning {
		font-family: 'PingFang TC';
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 18px;
		color: #9e9e9e;
		padding: 8px 16px 44px;
		background-color: #e5e5e5;
	}

	div.policy > div {
		border-bottom: 0.5px solid #ccc;
		:last-child {
			border-bottom: none;
		}
	}
`;

const AttentionInfo = ({
	eventType,
	warning,
	policy,
	confirmStatement,
	togglePolicyChecked,
	additionalPolicy = [],
}) => {
	if (eventType === 'recruit' || eventType === 'lineliff') {
		const memberData = {
			text: '必要會員資料',
			description: policy,
		};
		const authData = {
			text: '授權登錄活動－發票資料',
			description: warning,
		};

		return (
			<RecruitPolicyStyle>
				<div className="policy">
					{!isEmpty(policy) && (
						<UserSwitch
							userData={memberData}
							onClick={() => togglePolicyChecked('policy1')}
						/>
					)}
					{!isEmpty(warning) && (
						<UserSwitch
							userData={authData}
							onClick={() => togglePolicyChecked('policy2')}
						/>
					)}
					{!isEmpty(additionalPolicy) &&
						additionalPolicy.map(([policyName, title, description]) => (
							<UserSwitch
								key={title}
								userData={{
									text: `${title}`,
									description: `${description}`,
								}}
								onClick={() => togglePolicyChecked(policyName)}
							/>
						))}
				</div>

				<p className="warning">
					未來若需解除會員、取消授權及其他相關事宜，請隨時聯絡發票存摺團隊以進行變更。
				</p>
			</RecruitPolicyStyle>
		);
	}

	return (
		<AttentionInfoStyle>
			<h1>注意事項</h1>
			<div className="attention-context">
				<MarkdownTransformer>{warning}</MarkdownTransformer>
			</div>
			<div className="attention-policy">
				此活動遵守{' '}
				<a className="policy" href="https://invoice.tw/app/">
					發票存摺使用者條款與隱私權政策
				</a>
				<MarkdownTransformer>{policy}</MarkdownTransformer>
			</div>
			<div className="checkbox-area">
				<UserCheckbox
					name="policy1"
					description={
						!isNil(confirmStatement) && !isEmpty(confirmStatement)
							? `${confirmStatement}`
							: '我已完整閱讀上述活動說明並同意上述使用條款。'
					}
					onClick={() => togglePolicyChecked('policy1')}
				/>
				<UserCheckbox
					name="policy2"
					description="我已確認上述資訊均正確無誤，若因資訊錯誤造成無法配送將不做任何補償。"
					onClick={() => togglePolicyChecked('policy2')}
				/>
				{!isEmpty(additionalPolicy) &&
					additionalPolicy.map(([policyName, title, policy]) => (
						<UserCheckbox
							key={title}
							name={policyName}
							description={`${policy}`}
							onClick={() => togglePolicyChecked(policyName)}
						/>
					))}
			</div>
		</AttentionInfoStyle>
	);
};

export default AttentionInfo;
