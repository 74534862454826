import React from 'react';
import styled from 'styled-components';
import MarkdownTransformer from '../basic/MarkdownTransformer';

const Label = styled.label`
	position: relative;
	display: flex;
	font-size: 13px;
	color: #9b9b9b;
	padding-left: 36px;
	align-items: center;
	margin: 10px 0;

	input {
		position: absolute;
		cursor: pointer;
		opacity: 0;
		height: 0;
		width: 0;

		:checked {
			~ .checkmark {
				background-color: #00aeef;
				border: 1px solid #00aeef;
			}
			~ div.description {
				color: #00aeef;
				a {
					color: #1e90ff;
					font-weight: 700;
				}
			}
			~ .checkmark:after {
				display: block;
			}
		}
	}

	.checkmark {
		position: absolute;
		left: 0;
		height: 22px;
		width: 22px;
		border: 1px solid #c7c7cd;
		border-radius: 50%;

		:after {
			content: '';
			position: absolute;
			display: none;

			left: 6px;
			top: 2px;
			width: 6px;
			height: 12px;
			border: solid white;
			border-width: 0 1px 1px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
`;

const UserCheckbox = (props) => {
	const { onClick, description, name } = props;

	return (
		<Label>
			<input type="checkbox" name={name} onChange={onClick} />
			<div className="description">
				<MarkdownTransformer>{description}</MarkdownTransformer>
			</div>
			<span className="checkmark"></span>
		</Label>
	);
};

export default UserCheckbox;
