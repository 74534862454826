export const config = {
  apiKey: "AIzaSyDWlju7imEdoHVH2tQm0UFs_RbHyFjMANM",
  authDomain: "invoice-frontend.firebaseapp.com",
  databaseURL: "https://invoice-frontend-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "invoice-frontend",
  storageBucket: "invoice-frontend.appspot.com",
  messagingSenderId: "960919218798",
  appId: "1:960919218798:web:3da3d52bcc82063b10147f"
}

export const apiUrl = process.env.NODE_ENV === "production" ? "https://api.invoice.tw" : "" ;