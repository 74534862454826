import { differenceInYears, format, parse } from 'date-fns';
import { compose, path, merge, join, map, toPairs, omit, zip } from 'ramda';

const convertImageValueToBase64 = async (item, array) => {
	const { file, name } = item;

	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			const b64code = reader.result.split(',')[1];

			resolve([...array, { filename: name, b64code: b64code }]);
		};
		reader.onerror = () => {
			reject(reader.error);
		};
		reader.readAsDataURL(file[0]);
	});
};

const generatorFirebaseValue = (value) => {
	return merge(path(['data', 'public'])(value), path(['data', 'auth'])(value));
};

const assembleInputValue = (value) => {
	return join(
		'||',
		map((item) => {
			return join('|', item);
		})(toPairs(value))
	);
};

const setLocal = (name, data) =>
	localStorage.setItem(name, JSON.stringify(data));
const getLocal = (name) => JSON.parse(localStorage.getItem(name));
const updateLocal = (name, updateData) => {
	const previousLocalData = getLocal(name);
	const newData = { ...previousLocalData, ...updateData };
	setLocal(name, newData);
};

const removeLocal = (name, removeKey) => {
	const previousLocalData = getLocal(name);
	const newData = omit([removeKey])(previousLocalData);
	setLocal(name, newData);
};

const deleteLocal = (name) => localStorage.removeItem(name);

const oneDay = 24 * 60 * 60 * 1000;
const getInvosQueryString = compose(
	join('&'),
	map(join('=')),
	toPairs,
	omit(['event_status'])
);

function calculateAge(date) {
	const formatDate = 'yyyy/MM/dd';
	const birthday = parse(format(date, formatDate), formatDate, new Date());
	const age = differenceInYears(new Date(), birthday);
	return age;
}

const letterDictonary = {
	A: '10',
	B: '11',
	C: '12',
	D: '13',
	E: '14',
	F: '15',
	G: '16',
	H: '17',
	I: '34',
	J: '18',
	K: '19',
	L: '20',
	M: '21',
	N: '22',
	O: '35',
	P: '23',
	Q: '24',
	R: '25',
	S: '26',
	T: '27',
	U: '28',
	V: '29',
	W: '32',
	X: '30',
	Y: '31',
	Z: '33',
};

function strictValidationUserId(id) {
	const isFirstTwoLetterEng = /^[A-Z][A-D]/; //舊版本居留證結構

	let totalSum = 0;
	const [firstLetter, ...idNumbers] = id;
	if (!/^[A-Z]/.test(firstLetter) || id.length !== 10) {
		return false;
	}

	const restNumbers = isFirstTwoLetterEng.test(id)
		? [letterDictonary[idNumbers.shift()][1], ...idNumbers]
		: idNumbers;

	const allNumbers = []
		.concat(letterDictonary[firstLetter].split(''))
		.concat(...restNumbers);

	const weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

	for (let index = 0; index < 11; index++) {
		totalSum += allNumbers[index] * weights[index];
	}

	return totalSum > 0 && totalSum % 10 === 0;
}

// because policy1 and policy2 exist, so start with 3
const ADDITIONAL_POLICY_INDEX = 3;

export {
	calculateAge,
	convertImageValueToBase64,
	generatorFirebaseValue,
	assembleInputValue,
	setLocal,
	strictValidationUserId,
	getLocal,
	updateLocal,
	removeLocal,
	deleteLocal,
	oneDay,
	getInvosQueryString,
	ADDITIONAL_POLICY_INDEX,
};
