import firebase from 'firebase/app';
import 'firebase/database';
import { isNil } from 'ramda';

import { config } from '../config';

const app = firebase.initializeApp(config);
const database = app.database();


export const getDataFromFirebase = (path) =>{
    return new Promise((resolve,reject)=>{
        database.ref(path).once("value",(e)=>{
            if(isNil(e.val())) reject(new Error('no data received'))
            resolve({value:e.val(),key:e.key})
        })
    })
}