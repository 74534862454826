import { isEmpty } from 'ramda';
import React from 'react';
import BarLoader from 'react-spinners/BarLoader'
import styled from 'styled-components'

const ButtonStyle = styled.div`
  position: fixed;
	bottom: 0;
  left: 0;
  right:0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 68px;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
  z-index: 200;

  display:flex;
  justify-content: center;
  
  button {
    background: #00AEEF;
    border: 1px solid #00AEEF;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'PingFang TC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    height: 44px;
    width: 100%;
    max-width: 366px;
    margin: 0 24px;
    padding: 14px 0;

		:focus {
			outline: none;
			box-shadow: 0 0 1px 1px #ccc;
		}

		:disabled {
			background-color: #ccc;   
			border: solid 1px #ccc;
		}

		a {
			color: #ffffff;
			width: 100%;
			padding: 14px 0;
			display: block;
      text-decoration: none;
      padding: 0;
    }

    p {
      margin-bottom: 4px;
    }
  }
`

const linkOutText = (linkoutUrl,disabledCondition,text) => {
  if(!isEmpty(linkoutUrl) && !disabledCondition) return <a href={linkoutUrl}>{ text || "前往" }</a>
  return <span>{text || "前往"}</span>
}

function ActionBtn(props) {
  const {
    formId,
    action,
    disabledCondition,
    linkoutUrl,
    text,
    isLoading,
    eventType
  } = props

  return(
  <ButtonStyle>
    <button 
      className={ isLoading ? "bar-loader-area" : "user-info-submit-btn"}
      form={ formId || "" }
      type={ formId ? "submit" : "" }
      disabled={ disabledCondition }
      onClick={ action && (() => action()) }
      data-event={eventType}
      id="action-button"
    >
      {
        isLoading
        ? <>
            <p className="processing">處理中...</p>
            <BarLoader 
              height={4}
              width={"100%"}
              color={"#fff"}
            /> 
          </>
        : linkoutUrl
          ? linkOutText(linkoutUrl,disabledCondition,text)
          : text || "送出"
      }
    </button>
  </ButtonStyle>
  )
}

export default ActionBtn