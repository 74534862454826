import { isEmpty } from 'ramda';
import React from 'react';
import styled from 'styled-components';

const BannerLogoStyle = styled.div`
	position: relative;
	height: ${(props) => (props.bannerHeight ? '156px' : '86px')};

	.product-banner {
		height: ${(props) => (props.bannerHeight ? '126px' : '56px')};
		position: relative;
		width: 100%;
	}

	.overlay {
		height: ${(props) => (props.bannerHeight ? '127px' : '57px')};
		position: absolute;
		width: 100%;
		top: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0) 55%,
			white
		);
		z-index: 100;
	}

	.product-logo {
		display: flex;
		flex-flow: row;
		justify-content: ${(props) => (props.logoCenter ? 'center' : 'flex-start')};

		img {
			position: relative;
			top: ${(props) => (props.bannerHeight ? '-35px' : '25px')};
			margin: 0 24px;
			width: 60px;
			height: 60px;
			border-radius: ${(props) => (props.logoCenter ? '0px' : '40px')};
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
			border: ${(props) =>
				props.logoCenter ? 'solid 0.5px #CCCCCC' : 'solid 0.5px #eeeeee'};
			z-index: 200;
			background-color: #ffffff;
		}
	}
`;

const BannerLogo = ({ bannerLink, logoLink, eventType }) => {
	const isRecruit = eventType === 'recruit' || eventType === 'lineliff';
	const hasBannerLink = !isEmpty(bannerLink);
	const hasLogoLink = !isEmpty(logoLink);

	return (
		<BannerLogoStyle bannerHeight={hasBannerLink} logoCenter={isRecruit}>
			{hasBannerLink && (
				<>
					<img
						className="product-banner"
						src={bannerLink}
						alt="product-banner"
					/>
					<div className="overlay"></div>
				</>
			)}
			{hasLogoLink && (
				<div className="product-logo">
					<img src={logoLink} alt="product-logo" />
				</div>
			)}
		</BannerLogoStyle>
	);
};

export default BannerLogo;
