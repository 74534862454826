import React, { useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import {
	getLocal,
	deleteLocal,
	updateLocal,
	removeLocal,
} from '../../helper/tool';
import {
	compose,
	concat,
	isEmpty,
	isNil,
	omit,
	path,
	pick,
	split,
	when,
} from 'ramda';
import liff from '@line/liff';
import { contextStore } from '../../App';
import Main from '../Main';
import LineLiffOutcomePage from './LineLiffOutcomePage';
import LoadingPage from './LoadingPage';

export default function LineLiffEndPoint() {
	const [liffLoginStatus, setLiffLoginStatus] = useState(null);

	const {
		event,
		handleAdConversionWithEndPoint: handleAdConversion,
	} = useContext(contextStore);
	const liffIdInContext = path(['data', 'liffId'])(event);
	const logoLink = path(['data', 'logoLink'])(event);

	const queryStringParams = queryString.parse(window.location.search);

	const isFromInvos = path(['isFromInvos'])(queryStringParams);

	const liffClientIdFromQueryString = path(['liffClientId'])(queryStringParams);
	const liffIdInLocalStorage = compose(
		path(['liffId']),
		getLocal
	)(liffClientIdFromQueryString);

	const liffId = !isNil(liffIdInContext)
		? liffIdInContext
		: liffIdInLocalStorage;

	const isAllowOldFriend = path(['liffAllowOldFriend'])(
		getLocal(liffClientIdFromQueryString)
	);

	const friendshipStatusFromQueryString = path(['friendship_status_changed'])(
		queryStringParams
	);
	const lineErrorStatusFromQueryString = path(['error'])(queryStringParams);
	const lineStateFromQueryString = path(['state'])(queryStringParams);

	const isFriendStatusExistInLocalStorage = !isNil(
		path(['friendship_status_changed'])(getLocal(liffClientIdFromQueryString))
	);
	const isFriendStatusExistInQueryString = !isNil(
		friendshipStatusFromQueryString
	);
	const isFriendStatusInQueryStringTrue =
		isFriendStatusExistInQueryString &&
		friendshipStatusFromQueryString === 'true';

	const userSubmitParamsFromLocalStorage = path(['liffUserSubmit'])(
		getLocal(liffClientIdFromQueryString)
	);

	const customInputValue = path(['customInput'])(
		getLocal(liffClientIdFromQueryString)
	);

	useEffect(() => {
		document.title = '發票存摺活動專區';
		if (!isNil(logoLink)) {
			// 如果有logo圖片 存進storage裡
			const liffStorageKey = compose(path([0]), split('-'))(liffIdInContext);
			updateLocal(liffStorageKey, {
				logoLink,
			});
		}

		if (isNil(lineStateFromQueryString) && isNil(liffClientIdFromQueryString)) {
			// 判定是? 完全沒有query-string => 不做下面的liff.init
			setLiffLoginStatus('');
			return;
		}

		if (!isNil(lineErrorStatusFromQueryString)) {
			// 如果網址裡面有 error state 就是錯誤狀態
			setLiffLoginStatus('');
			return;
		}

		if (
			isNil(getLocal(liffClientIdFromQueryString)) ||
			isEmpty(getLocal(liffClientIdFromQueryString))
		) {
			// 如果 localstorage裡沒有這檔liff data, 就是異常狀態
			setLiffLoginStatus('');
			return;
		}

		// if (isEmpty(document.referrer) && !isFromInvos) {
		// 	// 用改網址的!
		// 	setLiffLoginStatus(true);
		// 	return;
		// }

		liff.init({ liffId: !isNil(liffId) && liffId }).then(async () => {
			if (liff.isLoggedIn()) {
				if (!isNil(isFromInvos)) {
					// 點擊送出user資料表單來的 會帶著isFromInvos參數
					liff.logout();
					liff.login();
					return;
				}

				// setLiffLoginStatus(true);

				const userLineId = path(['userId'])(await liff.getProfile());

				if (isFriendStatusExistInQueryString) {
					// 網址裡有firendship Change 參數：user未許可/連動過 OA
					// console.log('user有點擊許可');

					updateLocal(liffClientIdFromQueryString, {
						friendship_status_changed: isFriendStatusInQueryStringTrue,
						userLineId,
					});

					// 打api ?
					const lineApiDataConfig = {
						...omit([
							'friendship_status_changed',
							'liffId',
							'userLineId',
							'logoLink',
							'customInput',
							'liffAllowOldFriend',
						])(getLocal(liffClientIdFromQueryString)),
						'cus_item[]': compose(
							when(
								() => isAllowOldFriend,
								concat(`friendStatus|${isFriendStatusInQueryStringTrue}||`)
							),
							when(
								() => !isEmpty(customInputValue),
								concat(`${customInputValue}||`)
							),
							concat('userLineId|'),
							path(['userLineId']),
							pick(['userLineId'])
						)(getLocal(liffClientIdFromQueryString)),
					};

					if (isAllowOldFriend) {
						await handleAdConversion(lineApiDataConfig).then((res) => {
							if (res === 200) {
								setLiffLoginStatus('success');
								removeLocal(liffClientIdFromQueryString, 'liffUserSubmit');
								return;
							}
							deleteLocal(liffClientIdFromQueryString);
							removeLocal(liffClientIdFromQueryString, 'liffUserSubmit');
							setLiffLoginStatus('unqualified');

							return;
						});
						return;
					}

					if (
						isFriendStatusInQueryStringTrue &&
						!isNil(userSubmitParamsFromLocalStorage)
					) {
						console.log('打api with,', lineApiDataConfig);

						await handleAdConversion(lineApiDataConfig).then((res) => {
							console.log('what is res', res);

							if (res === 200) {
								setLiffLoginStatus('success');
								removeLocal(liffClientIdFromQueryString, 'liffUserSubmit');
								return;
							}
							deleteLocal(liffClientIdFromQueryString);
							removeLocal(liffClientIdFromQueryString, 'liffUserSubmit');
							setLiffLoginStatus('unqualified');
							return;
						});
					} else {
						removeLocal(liffClientIdFromQueryString, 'liffUserSubmit');
						setLiffLoginStatus('unqualified');
					}
				} else {
					// 網址裡沒有friendship chagne參數， user已經連過動了... 顯示不符合任務

					removeLocal(liffClientIdFromQueryString, 'liffUserSubmit');
					setLiffLoginStatus('unqualified');
				}
			} else {
				// user 沒有登入 liff
				liff.login();
			}
		});
	}, [liffId]);

	// console.log('context in line liff page:', content);

	return isNil(liffLoginStatus) || !isNil(isFromInvos) ? (
		<LoadingPage />
	) : !isNil(friendshipStatusFromQueryString) ||
	  !isNil(lineErrorStatusFromQueryString) ||
	  !isNil(path(['state'])(queryStringParams)) ||
	  !isNil(liffLoginStatus) ? (
		<LineLiffOutcomePage liffLoginStatus={liffLoginStatus} />
	) : (
		<Main />
	);
}
