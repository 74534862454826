import React from 'react';
import { SelectGroup, DatePickers } from './style/InputStyle';
import { Controller } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import zhLocale from 'date-fns/locale/zh-TW';
import { isEmpty, isNil } from 'ramda';

const defaultMaterialTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#00aeef',
			contrastText: '#fff',
		},
	},
});

function UserBirth(props) {
	const {
		rules,
		errors,
		control,
		eventType,
		inputName,
		label = '出生年月日',
		placeholder = '年 / 月 / 日',
		watch,
	} = props.userData;

	const hasError = errors[inputName];
	const isRecruitType = eventType === 'recruit' || eventType === 'lineliff';

	const checkError = (hasError) => {
		if (isNil(hasError)) {
			if (!isNil(watch(inputName)) && !isEmpty(watch(inputName))) {
				return <div className="check"></div>;
			}
		} else {
			switch (hasError.type) {
				case 'pattern':
				case 'regex':
					return <p className="error-hint">{hasError.message || '格式錯誤'}</p>;
				case 'required':
					if (isRecruitType) return;
					return <p className="error-hint">必填欄位</p>;
				default:
					return null;
			}
		}
	};

	return (
		<SelectGroup isRecruitType={isRecruitType}>
			<label style={{ marginBottom: `${label ? '0' : '4px'}` }}>{label}</label>

			<div>
				<ThemeProvider theme={defaultMaterialTheme}>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhLocale}>
						<Controller
							as={
								<DatePickers
									disableFuture
									placeholder={placeholder}
									inputVariant="outlined"
									openTo="year"
									format="yyyy/MM/dd"
									views={['year', 'month', 'date']}
									errors={errors[inputName]}
									isrecruittype={eventType}
								/>
							}
							name={inputName}
							control={control}
							rules={rules}
							defaultValue={null}
						/>
					</MuiPickersUtilsProvider>
				</ThemeProvider>

				{checkError(hasError)}
			</div>
		</SelectGroup>
	);
}

export default UserBirth;
