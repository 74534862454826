import React, { useEffect, useContext } from 'react';
import { path } from 'ramda';
import BasePage from './layout/BasePage';
import UserApplyPage from './content/UserApplyPage';
import AlreadyApplyPage from './content/AlreadyApplyPage';
import ApplyEventOverPage from './content/ApplyEventOverPage';
import { contextStore } from '../App';

function Main() {
	const { status, event } = useContext(contextStore);

	const eventType = path(['data', 'eventType'])(event);
	const isLinkout = eventType === 'linkout';
	const isDashboard = eventType === 'dashboard';
	const isLineLiff = eventType === 'lineliff';
	useEffect(() => {
		if (status === '-1') {
			document.title = '活動截止';
		} else if (status === '0') {
			document.title = '發票存摺活動專區';
		}
	}, [status]);

	return (
		<BasePage>
			{status === '-1' ? (
				<ApplyEventOverPage /> // 人數滿了嗎？活動截止了嗎？  userId : -1 :額滿
			) : status === '0' || isLinkout || isDashboard || isLineLiff ? (
				<UserApplyPage /> // 申請過了嗎？ API userId : 0 (false) =>未領取 ＝還沒申請過 // >0 timestamp 申請過了
			) : (
				<AlreadyApplyPage />
			)}
		</BasePage>
	);
}

export default Main;
