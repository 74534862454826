import React from 'react'
import styled from 'styled-components'
import invosIcon from '../../images/icon-invos.svg'

const HeaderStyle = styled.div`
	height: 48px;
	background-image: linear-gradient(to left, #1ad7f4, #00aeef);
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		color: #fff;
	}
	img {
		width: 24px;
		height: 24px;
		margin: 10px;
	}
`

const Header = ({text}) => {
  return (
    <HeaderStyle>
      <img alt="icon" src={invosIcon} />
      <p>{text}</p>
    </HeaderStyle>
  )
}

export default Header